
.recipient-list__select-list-count {
  position: relative;
}

.recipient-list__select-list-count-header {
  position: absolute;
  top: -16px;
  left: 0;
  font-size: 1.2rem;
  line-height: 1;
}
